import * as PropTypes from "prop-types"
import React from "react"
import { graphql } from 'gatsby'

import { QuestionAnswer } from "../../../components/index";
import { numberWithCommas } from '../../../utils/formatNumber'

class TotalTokensFaqPage extends React.PureComponent {
  render() {
    const {
      data: {
        faq,
        typeTotal,
        total,
        totalSets
      }
    } = this.props;
    const reprintedTokens = Number(total.totalCount) - Number(typeTotal.totalCount);
    const answer = `There are a total of ${numberWithCommas(total.totalCount)} token cards in Magic: the Gathering. Counting only those that are officially printed by Wizards of the Coast, spanning ${totalSets.totalCount} different sets. Of these tokens, ${typeTotal.totalCount} different types exist, meaning that ${reprintedTokens} are reprints of existing tokens.<br><br>You can view all ${numberWithCommas(total.totalCount)} tokens, ordered by set <a href='/tokens/'>here</a>, or view the ${typeTotal.totalCount} different token types, ordered alphabetically <a href'/token-list/'>here</a>.`
    return (
      <QuestionAnswer {...faq.edges[0].node} answer={answer}>
        <p dangerouslySetInnerHTML={{
          __html: answer
        }}/>
      </QuestionAnswer>
    )
  }
}

TotalTokensFaqPage.propTypes = {
  data: PropTypes.shape({
    typeTotal: PropTypes.shape({
      totalCount: PropTypes.number.isRequired
    }).isRequired,
    total: PropTypes.shape({
      totalCount: PropTypes.number.isRequired
    }).isRequired,
    totalSets: PropTypes.shape({
      totalCount: PropTypes.number.isRequired
    }).isRequired,
    faq: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            route: PropTypes.string.isRequired,
            question: PropTypes.string.isRequired,
            date: PropTypes.string.isRequired,
            formattedDate: PropTypes.string.isRequired,
          }),
        }),
      ),
    }),
  }).isRequired
}

export const totalFaqQuery = graphql`
  query TotalFAQQuery {
    typeTotal: allTypesJson {
      totalCount
    }
    total: allTokensJson(filter: {
      proxy: {
        ne: true
      }
    }) {
      totalCount
    }
    totalSets: allSetsJson {
      totalCount
    }
    faq: allFaqJson(filter: {
      id: {
        eq: "faq1"
      }
    }) {
      edges {
        node {
          route
          question
          date
          formattedDate: date(formatString: "MMM Do, YYYY")
        }
      }
    }
  }
`
export default TotalTokensFaqPage